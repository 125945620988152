import {NgIf} from '@angular/common';
import {Component, DestroyRef, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterOutlet} from '@angular/router';
import {FuseFullscreenComponent} from '@fuse/components/fullscreen';
import {FuseLoadingBarComponent} from '@fuse/components/loading-bar';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {LanguagesComponent} from 'app/layout/common/languages/languages.component';
import {MessagesComponent} from 'app/layout/common/messages/messages.component';
import {NotificationsComponent} from 'app/layout/common/notifications/notifications.component';
import {QuickChatComponent} from 'app/layout/common/quick-chat/quick-chat.component';
import {SearchComponent} from 'app/layout/common/search/search.component';
import {ShortcutsComponent} from 'app/layout/common/shortcuts/shortcuts.component';
import {UserComponent} from 'app/layout/common/user/user.component';
import {interval, map, switchMap} from 'rxjs';
import {SideNotificationsComponent} from "../../../../views/side-notifications/side-notifications.component";
import {Notification} from "../../../../model/notification";
import {NotificationService} from "../../../../services/notification.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Navigation} from "../../../../core/navigation/navigation.types";
import {NavigationService} from 'app/core/navigation/navigation.service';
import {AlertCenterService} from "@makeo-packages/mkongtools";

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, NotificationsComponent, UserComponent, NgIf, RouterOutlet, QuickChatComponent, FuseVerticalNavigationComponent, SideNotificationsComponent],
})
export class CompactLayoutComponent implements OnInit {

    public isScreenSmall: boolean;
    public notifications: Notification[];
    public userId: number;
    public navigation: Navigation;
    public opened: boolean = false;

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _notificationService: NotificationService,
        private _alertCenterService: AlertCenterService,
    ) {
    }

    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.getNotifications();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    onToggleSideNotification(open: boolean): void {
        this.opened = open;
    }

    private getNotifications(): void {
        // get current user notifications
        this._notificationService.getAllCurrentUserNotifications().pipe(
            takeUntilDestroyed(this.destroyRef),
            map(requestData => requestData.datas || requestData)
        ).subscribe({
            next: (notificationsData) => {
                this._notificationService.notifications.next(notificationsData);
            },
            error: () => {
                this._alertCenterService.error('Une erreur est survenue lors du chargement de vos notifications');
            }
        });

        // resend notification request every 5 minutes (1000 * 60 * 5 = 300000 milliseconds)
        interval(300000).pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap(() => this._notificationService.getAllCurrentUserNotifications()),
            map(requestData => requestData.datas || requestData)
        ).subscribe({
            next: (notificationsData) => {
                this._notificationService.notifications.next(notificationsData);
            },
            error: () => {
                this._alertCenterService.error('Une erreur est survenue lors du chargement de vos notifications');
            }
        });
    }
}
