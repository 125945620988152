import {DestroyRef, inject, Injectable} from '@angular/core';
import {AlertCenterService, RestApiService} from "@makeo-packages/mkongtools";
import {BehaviorSubject, map, Observable, ReplaySubject} from "rxjs";
import {Notification} from "../model/notification";
import {NotificationQueryParams} from "../model/notification_query_params";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NotificationState} from "../model/notification_state";

@Injectable({providedIn: 'root'})
export class NotificationService {
    public apiEndpoint: string = 'notifications';
    public notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        private apiService: RestApiService,
        private alertCenterService: AlertCenterService,
    ) {
    }

    public getOneNotification(id: number): Observable<any> {
        return this.apiService.get(this.apiEndpoint, id);
    }

    public getAllCurrentUserNotifications(): Observable<any> {
        return this.apiService.get(this.apiEndpoint + '/me');
    }

    public getAllNotifications(params?: any): Observable<any> {
        return this.apiService.get(this.apiEndpoint, null, null, params);
    }

    public createNotification(notification: Notification): Observable<any> {
        return this.apiService.post(this.apiEndpoint, notification);
    }

    public updateNotification(id: number, notification: Notification): Observable<any> {
        return this.apiService.put(this.apiEndpoint, id, notification);
    }

    public deleteNotification(id: number): Observable<any> {
        return this.apiService.delete(this.apiEndpoint, id);
    }

    /**
     *
     * getQueryParams: returns formatted database query params usage in angular router navigate method
     *
     * @param notification
     */
    public getQueryParams(notification: Notification) {

        const queryParams: { [key: string]: string } = {};

        if (notification.notification_query_params && notification.notification_query_params.length) {
            notification.notification_query_params.forEach((notificationQueryParams: NotificationQueryParams) => {
                queryParams[notificationQueryParams.params_key] = notificationQueryParams.params_value;
            });
        }

        return queryParams;
    }

    /**
     *
     * _createQueryParams : create a formatted NotificationQueryParams array
     *
     * @param queryParams
     * @private
     */
    private _createQueryParams(queryParams: any): NotificationQueryParams[] {
        const notificationQueryParams: NotificationQueryParams[] = [];

        for (const key in queryParams) {
            const value = queryParams[key];

            const newNotificationQueryParams: NotificationQueryParams = new NotificationQueryParams();
            newNotificationQueryParams.params_key = key;
            newNotificationQueryParams.params_value = value;

            notificationQueryParams.push(newNotificationQueryParams);
        }

        return notificationQueryParams;
    }

    /**
     *
     * generateNotification: generate notification based on multiple criteria
     *
     * @param object
     * @param content
     * @param context
     * @param entityId
     * @param rightIdentifiers
     * @param queryParams
     * @param notificationState
     */
    public generateNotification(object: string, content: string, context?: string, entityId?: number, notificationState?: NotificationState, rightIdentifiers?: string[], queryParams?: any): void {

        // create query params
        let notificationQueryParams: NotificationQueryParams[] = [];
        if (queryParams) {
            notificationQueryParams = this._createQueryParams(queryParams);
        }

        // create notification
        const notification: Notification = new Notification();

        notification.object = object;
        notification.content = content;
        notification.context = context;
        notification.entity_id = entityId;

        notification.notification_query_params = notificationQueryParams;
        notification.notification_state = notificationState;
        notification.rights_identifiers = rightIdentifiers;

        // create notification in db
        this.createNotification(notification).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(requestData => requestData.datas || requestData)
        ).subscribe({
            next: (notificationData) => {
                if (notificationData) {
                    this.alertCenterService.success('Notification envoyée avec succès');
                }
            },
            error: () => {
                this.alertCenterService.error('Une erreur est survenue lors de l\'envoi de la notification');
            }
        });

    }

    public getNumberOfNotifications(): Observable<number> {
        const subject: ReplaySubject<number> = new ReplaySubject<number>();

        this.notifications
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (notifications: Notification[]) => {
                    subject.next(notifications.length || 0);
                }
            });

        return subject.asObservable();
    }

}
