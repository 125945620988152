import {HTTP_INTERCEPTORS, provideHttpClient} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    inject,
    PLATFORM_ID
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    Router,
    RouterModule,
    withInMemoryScrolling,
    withPreloading
} from '@angular/router';
import { provideFuse } from '@fuse';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import {environment} from "../environments/environment";
import {AuthInterceptor, MkongusersModule} from "@makeo-packages/mkongusers";
import {MatPaginatorIntl} from "@angular/material/paginator";
import * as Sentry from "@sentry/angular-ivy";
import {getFrenchPaginatorIntl} from "./utils/french-paginator.intl";
import {CredentialsInterceptor} from "./helpers/credentials.interceptor";
import {ErrorHandlerService, MkongtoolsModule} from "@makeo-packages/mkongtools";
import {BrowserModule} from "@angular/platform-browser";
import {
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDialogModule} from "@angular/material/dialog";
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {isPlatformServer} from "@angular/common";
import {GlobalErrorHandlerService} from "./services/global-error-handler.service";

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom([
            RouterModule.forRoot(appRoutes, {
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled'
            }),
            BrowserModule,
            BrowserAnimationsModule,
            MkongusersModule,
            MkongtoolsModule,
            NgxMatMomentModule,
            NgxMatDatetimePickerModule,
            MatDialogModule,
            NgxMatTimepickerModule
        ]),
        provideAnimations(),
        provideHttpClient(),
        // provideRouter(appRoutes,
        //     withPreloading(PreloadAllModules),
        //     withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        // ),
        // Material Date Adapter
        {
            provide : DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: 'D',
                },
                display: {
                    dateInput         : 'DDD',
                    monthYearLabel    : 'LLL yyyy',
                    dateA11yLabel     : 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs      : [
                    {
                        id   : 'en',
                        label: 'English',
                    },
                    {
                        id   : 'fr',
                        label: 'French',
                    },
                ],
                defaultLang         : 'fr',
                fallbackLang        : 'fr',
                reRenderOnLangChange: true,
                prodMode            : true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            useFactory: () =>
            {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi     : true,
        },

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay   : 0,
                services: mockApiServices,
            },
            fuse   : {
                layout : 'classy',
                scheme : 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme  : 'theme-default',
                themes : [
                    {
                        id  : 'theme-default',
                        name: 'Default',
                    },
                    {
                        id  : 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id  : 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id  : 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id  : 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id  : 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        {
            provide: 'baseUrl', useValue: environment.apiAdress,
        },
        {
            provide: 'debounceTime', useValue: environment.debounceTime || 1
        },
        {
            provide: 'isMailLogin', useValue: environment.isMailLogin || false
        },
        {
            provide: 'isRemenberMe', useValue: environment.isRemenberMe || false
        },
        {
            provide: 'isAccesFormRegister', useValue: environment.isAccesFormRegister || true
        },
        {
            provide: 'isAccesFormForgotPassword', useValue: environment.isAccesFormForgotPassword || true
        },
        {
            provide: 'patternPasswordValidator', useValue: environment.patternPasswordValidator || ''
        },
        {
            provide: 'descriptionPatternPasswordValidator',
            useValue: environment.descriptionPatternPasswordValidator || ''
        },
        {
            provide: 'refreshTokenTtl', useValue: environment.refreshTokenTtl || 60
        },
        {
            provide: 'domain', useValue: environment.domain || ''
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CredentialsInterceptor,
            multi: true
        },
        {
            provide: 'sentryProviders',
            useFactory: provideSentryProviders,
            deps: [PLATFORM_ID],
        },
        { provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl()},
    ],
};


function provideSentryProviders(platformId: object): unknown[] {
    if (isPlatformServer(platformId)) {
        return [];
    }
    return [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                logErrors: true,
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ];
}
