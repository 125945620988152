import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';
import {TextFieldModule} from '@angular/cdk/text-field';
import {DatePipe, DOCUMENT, NgClass, NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {
    AfterViewInit,
    Component, DestroyRef,
    ElementRef, EventEmitter,
    HostBinding, inject,
    Inject, Input,
    OnDestroy,
    OnInit, Output,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {FuseScrollbarDirective} from '@fuse/directives/scrollbar';
import {Router, RouterLink} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Notification} from "../../model/notification";
import {MatOptionModule} from "@angular/material/core";
import {
    SideNotificationStateComponent
} from "../../common-components/side-notification-state/side-notification-state.component";
import {NotificationAction} from "../../model/notification_action";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {map} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import moment from "moment";
import {AlertCenterService} from "@makeo-packages/mkongtools";

@Component({
    selector: 'side-notifications',
    templateUrl: './side-notifications.component.html',
    styleUrls: ['./side-notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'sideNotifications',
    standalone: true,
    imports: [NgClass, NgIf, MatIconModule, MatButtonModule, FuseScrollbarDirective, NgFor, NgTemplateOutlet, MatFormFieldModule, MatInputModule, TextFieldModule, DatePipe, RouterLink, MatTooltipModule, MatOptionModule, SideNotificationStateComponent],
})
export class SideNotificationsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() opened: boolean = false;

    @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _mutationObserver: MutationObserver;
    private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
    private _overlay: HTMLElement;
    private destroyRef: DestroyRef = inject(DestroyRef);

    public queryParams: { [key: string]: string } = {};
    public notifications: Notification[];

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private _scrollStrategyOptions: ScrollStrategyOptions,
        private _notificationService: NotificationService,
        private _alertCenterService: AlertCenterService,
        private _router: Router,
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'quick-chat-opened': this.opened,
        };
    }

    ngOnInit(): void {
        this._notificationService.notifications
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((notifications: Notification[]) => {
               this.notifications = notifications;
            });
    }

    ngAfterViewInit(): void {
        // Fix for Firefox.
        //
        // Because 'position: sticky' doesn't work correctly inside a 'position: fixed' parent,
        // adding the '.cdk-global-scrollblock' to the html element breaks the navigation's position.
        // This fixes the problem by reading the 'top' value from the html element and adding it as a
        // 'marginTop' to the navigation itself.
        this._mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const mutationTarget = mutation.target as HTMLElement;
                if (mutation.attributeName === 'class') {
                    if (mutationTarget.classList.contains('cdk-global-scrollblock')) {
                        const top = parseInt(mutationTarget.style.top, 10);
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', `${Math.abs(top)}px`);
                    } else {
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', null);
                    }
                }
            });
        });
        this._mutationObserver.observe(this._document.documentElement, {
            attributes: true,
            attributeFilter: ['class'],
        });
    }

    ngOnDestroy(): void {
        // Disconnect the mutation observer
        this._mutationObserver.disconnect();
    }

    open(): void {
        // Return if the panel has already opened
        if (this.opened) {
            return;
        }

        // Open the panel
        this._toggleOpened(true);
    }

    close(): void {
        // Return if the panel has already closed
        if (!this.opened) {
            return;
        }

        // Close the panel
        this._toggleOpened(false);
    }

    toggle(): void {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private _showOverlay(): void {
        // Try hiding the overlay in case there is one already opened
        this._hideOverlay();

        // Create the backdrop element
        this._overlay = this._renderer2.createElement('div');

        // Return if overlay couldn't be create for some reason
        if (!this._overlay) {
            return;
        }

        // Add a class to the backdrop element
        this._overlay.classList.add('quick-chat-overlay');

        // Append the backdrop to the parent of the panel
        this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

        // Enable block scroll strategy
        this._scrollStrategy.enable();

        // Add an event listener to the overlay
        this._overlay.addEventListener('click', () => {
            this.close();
        });
    }

    private _hideOverlay(): void {
        if (!this._overlay) {
            return;
        }

        // If the backdrop still exists...
        if (this._overlay) {
            // Remove the backdrop
            this._overlay.parentNode.removeChild(this._overlay);
            this._overlay = null;
        }

        // Disable block scroll strategy
        this._scrollStrategy.disable();
    }

    private _toggleOpened(open: boolean): void {
        // Set the opened
        this.opened = open;

        // If the panel opens, show the overlay
        if (open) {
            this._showOverlay();
        }
        // Otherwise, hide the overlay
        else {
            this._hideOverlay();
            this._emitCloseEvent();
        }
    }

    private _emitCloseEvent(): void {
        this.closed.emit(true);
    }

    // handle different type of notification
    public onActionButtonClicked(notification: Notification, notificationAction: NotificationAction): void {
        switch (notificationAction?.notification_action_type?.name) {
            case 'see':
                this._redirectToAction(notification);
                break;
            case 'validate':
                this._validateNotification(notification);
                break;
            default:
                break;
        }
    }

    private _redirectToAction(notification: Notification): void {
        // redirect user based on notification query params
        if (notification.context && notification.notification_query_params && notification.notification_query_params.length) {
            this.queryParams = this._notificationService.getQueryParams(notification);
            if (notification.entity_id) {
                this._router.navigate([`${notification.context}/${notification.entity_id}`], {queryParams: this.queryParams}).then(() => {
                    this._emitCloseEvent();
                });
            } else {
                this._router.navigate([notification.context], {queryParams: this.queryParams}).then(() => {
                    this._emitCloseEvent();
                });
            }
        }
        // redirect user based on simple route
        else if (notification.context && notification.entity_id && notification.notification_query_params && notification.notification_query_params.length === 0) {
            this._router.navigate([`${notification.context}/${notification.entity_id}`]).then(() => {
                this._emitCloseEvent();
            });
        }
        else if (notification.context && !notification.entity_id && notification.notification_query_params && notification.notification_query_params.length === 0) {
            this._router.navigate([`${notification.context}`]).then(() => {
                this._emitCloseEvent();
            });
        }
    }

    // mark notification as seen and remove it from list
    private _validateNotification(notification: Notification): void {
        notification.seen_at = moment().toDate();

        this._notificationService.updateNotification(notification.id, notification).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(requestData => requestData.datas || requestData)
        ).subscribe({
            next: () => {
                this._alertCenterService.success('Notification validée avec succès');
                // remove notification from list
                this._notificationService.notifications
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((notifications: Notification[]) => {
                        if (notifications.findIndex((n: Notification) => n.id === notification.id) !== -1) {
                            notifications = notifications.filter((n: Notification) => n.id !== notification.id);
                            this._notificationService.notifications.next(notifications);
                        }
                    });
                // this.notifications = this.notifications.filter((n: Notification) => n.id !== notification.id);
                // this.updatedNotifications.emit(this.notifications);
            },
            error: () => {
                this._alertCenterService.error('Une erreur est survenue lors de la validation de la notification');
            }
        });
    }
}
