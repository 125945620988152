import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Navigation} from 'app/core/navigation/navigation.types';
import {Observable, of, ReplaySubject, tap} from 'rxjs';
import {adminNavigation} from "../../navigations/admin-navigation";
import {AuthUserService} from "../../services/auth-user.service";

@Injectable({providedIn: 'root'})
export class NavigationService {
    private _httpClient = inject(HttpClient);
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    constructor(private authService: AuthUserService) {
    }

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        let navigation;

        this.authService.userSubject.pipe().subscribe({
            next: (value) => {
                if (value) {


                    let nav: any[] = [];
                    if (this.authService.asRight('ACCESS_ADMIN_USER')) {
                        nav.push({
                            id: 'users',
                            title: 'Utilisateurs',
                            type: 'basic',
                            icon: 'heroicons_outline:users',
                            link: '/user',
                            exactMatch: true,
                        });
                    }
                    if (this.authService.asRight('ACCESS_ADMIN_CUSTOMER')) {
                        nav.push({
                            id: 'lists',
                            title: 'Administration des listes clients',
                            type: 'basic',
                            icon: 'heroicons_outline:briefcase',
                            // icon: 'heroicons_outline:identification',
                            link: '/list',
                            exactMatch: true,
                        });
                    }
                    if (this.authService.asRight('ACCESS_ADMIN_ANALYSIS')) {
                        nav.push({
                            id: 'lists-analysis',
                            title: 'Partie analyse',
                            type: 'basic',
                            icon: 'heroicons_outline:beaker',
                            link: '/list-analysis',
                            exactMatch: true,
                        });
                    }
                    if (this.authService.asRight('ACCESS_ADMIN_SPECIMEN')) {
                        nav.push({
                            id: 'lists-specimens',
                            title: 'Partie échantillon',
                            type: 'basic',
                            icon: 'heroicons_outline:clipboard-document-list',
                            link: '/list-specimens',
                            exactMatch: true,
                        });
                    }
                    if (this.authService.asRight('ACCESS_ADMIN_MATERIAL')) {
                        nav.push({
                            id: 'lists-materials',
                            title: 'Partie matériel',
                            type: 'basic',
                            icon: 'heroicons_outline:wrench-screwdriver',
                            link: '/list-materials',
                            exactMatch: true,
                        })
                    }
                    if (this.authService.asRight('ACCESS_ADMIN_KIT')) {
                        nav.push({
                            id: 'lists-kits',
                            title: 'Partie kit',
                            type: 'basic',
                            icon: 'heroicons_outline:shopping-bag',
                            link: '/list-kits',
                            exactMatch: true,
                        })
                    }

                    let index = adminNavigation.findIndex((element) => element.id === 'settings');
                    adminNavigation[index].children = nav;
                    navigation = <Navigation>{
                        compact: adminNavigation,
                        default: adminNavigation,
                        futuristic: adminNavigation,
                        horizontal: adminNavigation
                    }

                    this._navigation.next(navigation);
                }
            }
        })
        navigation = <Navigation>{
            compact: adminNavigation,
            default: adminNavigation,
            futuristic: adminNavigation,
            horizontal: adminNavigation
        }

        this._navigation.next(navigation);

        return of(navigation);

    }
}
