import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotificationState} from "../../model/notification_state";

@Component({
  selector: 'app-side-notification-state',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-notification-state.component.html',
  styleUrl: './side-notification-state.component.scss'
})
export class SideNotificationStateComponent {

    @Input() notificationState: NotificationState;
}
