import {DestroyRef, inject, Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {AuthService, UserService as AbstractUserService} from "@makeo-packages/mkongusers"
import {AppLocalStorageService, RestApiService} from "@makeo-packages/mkongtools";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {User} from "../model/user";
import {Right} from "../model/right";

@Injectable({
  providedIn: 'root'
})
export class AuthUserService extends AbstractUserService {
    public rightSubject: Subject<string> = new Subject();
    public right: any;
    public userSub: Subject<any> = new Subject();
    private destroyRef: DestroyRef = inject(DestroyRef);
    public userData : any;
    public userRight:any[];
    private api: RestApiService
    apiEndpoint = 'user';

    constructor(apiService: RestApiService, localStorage: AppLocalStorageService, authService: AuthService) {
        super(apiService, localStorage, authService);
        this.api = apiService
        this.userSubject.subscribe({
            next :(value)=>{
                this.userData = value;
                this.userRight = this.userData?.user_type?.user_right;
            }
        })
    }

    public getRightUser(){
        return this.api.get('user/right');
    }

    public checkEmail(email){
        return this.api.get(this.apiEndpoint + 'check-email', email);
    }

    public login(datas) {
        return this.api.post('login_check', datas)
    }

    public asRight(identifier : string):boolean{

        let found = this.userRight.find((r :Right)=>r.identifier === identifier);
        return !!found;

    }
}
