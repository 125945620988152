<button
    mat-icon-button
    (click)="openPanel()">
    @if (unreadCount > 0) {
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span style="background: var(--cl-green)"
                  class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full text-indigo-50 text-xs font-medium">
                {{ unreadCount }}
            </span>
        </span>
    }
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>
