<div
    class="fixed lg:sticky top-0 bottom-0 lg:left-full w-0 lg:w-0 lg:h-screen lg:shadow z-9999"
    [ngClass]="{'pointer-events-none select-none': !opened}">
    <div
        class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{'-translate-x-full sm:-translate-x-96 shadow': opened, 'translate-x-0': !opened}">

        <!-- Header -->
        <div
            class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer"
            [ngClass]="{'pointer-events-none select-none': !opened}"
            (click)="toggle()">

            <div class="flex flex-auto items-center justify-center">
                <div class="flex flex-0 items-center justify-center w-14">
                    <div class="lightGreenBackground flex flex-0 items-center justify-center w-8 h-8 rounded-full">
                        <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                </div>
                <div class="text-lg text-secondary font-bold grey">Notifications</div>
                <button
                    class="ml-auto mr-4"
                    [ngClass]="{'pointer-events-none select-none': !opened}"
                    mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>

        </div>

        <div class="flex flex-auto border-t overflow-hidden">

            <div class="flex flex-col flex-auto border-l overflow-hidden veryLightGreenBackground dark:bg-transparent">

                <!-- Notification list -->
                <div class="overflow-auto">
                    @for (notification of notifications; track notification.id) {
                        <div class="notificationContainer">
                            <app-side-notification-state
                                [notificationState]="notification.notification_state"></app-side-notification-state>
                            <span class="notificationObject">{{ notification.object }}</span>
                            <span class="notificationContent">{{ notification.content }}</span>
                            <span
                                class="notificationDate">{{ notification.created_at | date:'dd/MM/YYYY HH:mm' }}</span>
                            <div class="notificationActions">
                                @for (notificationAction of notification.notification_actions; track notificationAction.id) {
                                    <button (click)="onActionButtonClicked(notification, notificationAction)"
                                            class="notificationActionButton"
                                            [ngClass]="{'pointer-events-none select-none': !opened}">
                                        <mat-icon [svgIcon]="notificationAction.icon"></mat-icon>
                                        <span class="text">{{ notificationAction.name }}</span>
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>

                <!-- No notifications -->
                @if (!notifications || !notifications.length) {
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div
                            class="lightGreenBackground flex flex-0 items-center justify-center w-14 h-14 rounded-full">
                            <mat-icon
                                [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight grey">Aucune notification</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Lorsque vous recevrez des
                            notifications, elles apparaîtront ici.
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
