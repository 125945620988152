import {FuseNavigationItem} from "../../@fuse/components/navigation";

export let adminNavigation: FuseNavigationItem[];

adminNavigation = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard',
        exactMatch: true,

    },
    {
        id: 'customer',
        title: 'Clients',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/customer',
        exactMatch: false
    },
    {
        id: 'analysis',
        title: 'Analyses',
        type: 'basic',
        icon: 'heroicons_outline:beaker',
        link: '/analysis',
        exactMatch: true
    },
    {
        id: 'specimen',
        title: 'Échantillons',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-list',
        link: '/specimen',
        exactMatch: true
    },
    {
        id: 'register-specimen',
        title: 'Enregistrer un échantillon',
        type: 'basic',
        icon: 'heroicons_outline:document-plus',
        link: '/specimen/register',
        queryParams: { context: 'create' },
        exactMatch: true
    },
    {
        id: 'report',
        title: 'Bulletins',
        type: 'basic',
        icon: 'heroicons_outline:document-chart-bar',
        link: '/report',
        exactMatch: true
    },
    {
        id: 'extraction',
        title: 'Extraction de données',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/extraction',
        exactMatch: true
    },
    {
        id: 'settings',
        title: 'Paramètres',
        type: 'aside',
        icon: 'heroicons_outline:cog-8-tooth',
        children: []
    }
];
