import {Component, OnInit} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from '@angular/router';
import {HttpClientModule} from "@angular/common/http";
import {AuthModule, AuthService} from "@makeo-packages/mkongusers";
import {DestroyerService, AlertCenterService, MkAlertCenterComponent} from "@makeo-packages/mkongtools";
import {CommonModule} from "@angular/common";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FuseAlertComponent} from "../@fuse/components/alert";
import {ReactiveFormsModule} from "@angular/forms";
import {takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import 'ag-grid-enterprise';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {AuthUserService} from "./services/auth-user.service";
import {LicenseManager} from "ag-grid-enterprise";
import {LoaderComponent} from "./common-components/loader/loader.component";
import {TuiLoaderModule} from "@taiga-ui/core";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,
        HttpClientModule,
        CommonModule,
        ReactiveFormsModule,
        FuseAlertComponent,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        MatProgressSpinnerModule,
        AuthModule,
        MatDatepickerModule,
        MkAlertCenterComponent,
        MatBottomSheetModule,
        LoaderComponent,
        TuiLoaderModule
    ],
})
export class AppComponent implements OnInit {

    public isLoad =false;
    /**
     * Constructor
     */
    constructor(
        private authService: AuthService,
        private destroyService: DestroyerService,
        private router: Router,
        private authUserService: AuthUserService,
        private alertCenterService: AlertCenterService,
        private translateService: TranslateService
    ) {
        // TODO Replace with a non-stolen key
        LicenseManager.setLicenseKey("Peace_OTY2OTQ1OTQ1Njk3Mw==7e213e88aef89910e528cf77b5ac1af0");
    }

    ngOnInit() {
        this.authService.check().pipe(takeUntil(this.destroyService._onDestroy)).subscribe({
            next: (res) => {
                if (res) {
                    this.authUserService.getUserInfos()
                        .pipe(takeUntil(this.destroyService._onDestroy))
                        .subscribe({
                            next: data => {
                                if (data) {
                                    this.authUserService.userSubject.next(data.datas);
                                    this.isLoad = true
                                } else {
                                    this.router.navigate(['/connexion']).then(x => x);
                                    this.isLoad = true

                                }
                            },
                            error: (error: any) => {
                                this.alertCenterService.error(this.translateService.instant(error.error.message), true);
                                this.isLoad = true

                            }
                        })
                } else {
                    this.router.navigate(['/connexion']).then(x => x);
                    this.isLoad = true

                }
            }
        })
        this.authService.signInEvent.pipe(takeUntil(this.destroyService._onDestroy)).subscribe({
            next: () => {
                this.checkCurrentUser();
                this.isLoad = true

            }
        })
    }

    private checkCurrentUser(redirectToLogin = false) {
        this.authUserService.getUserInfos()
            .pipe(takeUntil(this.destroyService._onDestroy))
            .subscribe({
                next: data => {
                    if (data) {
                        this.authUserService.userSubject.next(data.datas);
                        this.router.navigate(['/dashboard']).then(x => x);
                        this.isLoad = true

                    } else if (redirectToLogin) {
                        this.router.navigate(['/connexion']).then(x => x);
                        this.isLoad = true

                    }
                },
                error: (error: any) => {
                    this.alertCenterService.error(this.translateService.instant(error.error.message), true);
                    this.isLoad = true

                }
            })
    }
}
