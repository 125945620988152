<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions"
    class="flex items-center justify-center">
    <span class="relative flex items-center justify-center">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user?.avatar"
            [src]="user?.avatar" alt="">
        <mat-icon
            *ngIf="!showAvatar || !user?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
<!--        <span-->
<!--            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"-->
<!--            [ngClass]="{'mr-px mb-px': !showAvatar || !user?.avatar,-->
<!--                          'bg-green-500': user?.status === 'online',-->
<!--                          'bg-amber-500': user?.status === 'away',-->
<!--                          'bg-red-500': user?.status === 'busy',-->
<!--                          'bg-gray-400': user?.status === 'not-visible'}"-->
<!--        ></span>-->
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
<!--    <button mat-menu-item>-->
<!--        <span class="flex flex-col leading-none">-->
<!--            <span>Signed in as</span>-->
<!--            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>-->
<!--        </span>-->
<!--    </button>-->
<!--    <mat-divider class="my-2"></mat-divider>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>-->
<!--        <span>Mon Profil</span>-->
<!--    </button>-->
<!--    <mat-divider class="my-2"></mat-divider>-->
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Déconnexion</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
