import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import * as Sentry from "@sentry/angular";
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";

 Sentry.init({
    dsn: "https://7f1e56705610f86af601b0f10a0b5ca6@o401086.ingest.us.sentry.io/4507464374222848",
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
       Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),
    ],
   enabled: environment.sentryEnable,


    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
   tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: ["localhost", environment.apiAdress],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
   replaysSessionSampleRate: 0.1,
   replaysOnErrorSampleRate: 1.0,
});

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
