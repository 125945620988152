import {DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component, DestroyRef, EventEmitter, inject, OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterLink} from '@angular/router';
import {NotificationService} from "../../../services/notification.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    exportAs: 'notifications',
    standalone: true,
    imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class NotificationsComponent implements OnInit {

    public unreadCount: number = 0;

    @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.notificationService.getNumberOfNotifications()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (notificationNumber: number) => {
                    this.unreadCount = notificationNumber;
                }
            });
    }

    openPanel(): void {
        this.opened.emit(true);
    }
}
