export const environment = {
    production: false,
    apiAdress: 'https://api.calia-dev.staging.makeo.fr/api',
    debounceTime: 1,
    refreshTokenTtl: 60,
    domain: 'calia-dev.staging.makeo.fr',
    isMailLogin: false,
    isRemenberMe: true,
    isAccesFormRegister: true,
    isAccesFormForgotPassword: true,
    patternPasswordValidator: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$\\-!%*?&])[A-Za-z\\d@$\\-!%*?&]{8,}$',
    descriptionPatternPasswordValidator: 'Votre mot de passe doit : comporter 8 caractères minimum et contenir 1 chiffre, 1 majuscule et 1 minuscule.',
    sentryEnable: false
};
