
<mk-alert-center></mk-alert-center>
@if (isLoad){
    <router-outlet></router-outlet>
} @else {
    <div class="fuse-splash-screen">
        <img
            src="assets/images/logo/logo.png"
            alt="Fuse logo">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
}
