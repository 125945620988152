import {Populator} from "./populator";
import {NotificationType} from "./notification_type";
import {NotificationState} from "./notification_state";
import {NotificationAction} from "./notification_action";
import {NotificationQueryParams} from "./notification_query_params";
import {Right} from "./right";

export class Notification extends Populator {
    id?: number;
    object?: string;
    content?: string;
    created_at?: Date;
    rights?: Right[];
    rights_identifiers?: string[];
    seen_at?: Date;
    entity_id?: number;
    context?: string;
    notification_type?: NotificationType;
    notification_state?: NotificationState;
    notification_actions?: NotificationAction[];
    notification_query_params?: NotificationQueryParams[];
}
